<template>
  <p></p>
  <router-link v-if="is_super" to="/wizards/groupoperations/changegame" class="nav-link">
    <font-awesome-icon class="p-1" icon="dice" />{{ $t('wizards.groupoperations.changingTheList') }}
  </router-link>

  <router-link v-if="is_super" to="/wizards/groupoperations/changehardlimit" class="nav-link">
    <font-awesome-icon class="p-1" icon="hands" />{{ $t('wizards.groupoperations.changingHardlimit') }}
  </router-link>

  <router-link v-if="is_super || is_admin_sb" to="/wizards/groupoperations/changejp" class="nav-link">
    <font-awesome-icon class="p-1" icon="trophy" />{{ $t('wizards.groupoperations.changejp') }}
  </router-link>
</template>

<script setup>
import WizardsService from "../../services/wizards.service";
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {VueGoodTable} from "vue-good-table-next";
import {computed, inject, onMounted, ref, watch, watchEffect} from "vue";
import {useStore} from "vuex";

const $t = inject('$t')

const is_super = inject('is_super')
const is_admin_sb = inject('is_admin_sb')
</script>

<style scoped>
</style>